<!-- Vertical layout -->
@if (layout === 'vertical') {
  <ng-scrollbar style="height: calc(100vh - 88px)" visibility="hover" (clickOutside)="navMob()" id="nav-ps-mantis">
    <div class="navbar-content">
      <ul class="nav coded-inner-navbar" (clickOutside)="fireOutClick()" (mouseleave)="navMob()">
        @for (item of navigations; track item) {
          @if (item.type === 'group') {
            <app-nav-group [item]="item"></app-nav-group>
          }
        }
      </ul>
      <div class="version">
        <label disabled class="pe-auto">v{{ currentApplicationVersion }}</label>
      </div>
    </div>
  </ng-scrollbar>
} @else if (layout === 'horizontal') {
  <div class="navbar-content sidenav-horizontal" id="layout-sidenav">
    <a class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
    <div #navbarWrapper class="sidenav-horizontal-wrapper">
      <ul
        #navbarContent
        id="side-nav-horizontal"
        class="nav coded-inner-navbar sidenav-inner"
        (clickOutside)="fireLeave()"
        (mouseleave)="fireLeave()"
      >
        @for (item of navigations; track item) {
          @if (item.type === 'group') {
            <app-nav-group [item]="item"></app-nav-group>
          }
        }
      </ul>
    </div>
    <a class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
  </div>
}

<!-- Compact Layout -->
<div class="navbar-content">
  @if (layout === 'compact') {
    <ng-scrollbar style="height: calc(100vh - 80px)" id="nav-ps-mantis" visibility="hover">
      <ul class="nav coded-inner-navbar" (clickOutside)="fireOutClick()" (mouseleave)="navMob()">
        @for (item of navigations; track item) {
          @if (item.type === 'group') {
            <app-nav-group [item]="item" (showCollapseItem)="subMenuCollapse($event)"></app-nav-group>
          }
        }
      </ul>
    </ng-scrollbar>
  }
</div>
@if (layout === 'compact') {
  @if (collapseItem) {
    <div class="pc-compact-submenu">
      <div class="pc-compact-title">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <div class="avatar avatar-xs bg-light-primary">
              <i antIcon type="{{ collapseItem.icon }}" theme="outline"></i>
            </div>
          </div>
          <div class="flex-grow-1 ms-2">
            <h5 class="mb-0">{{ collapseItem.title }}</h5>
          </div>
        </div>
      </div>
      <div class="collapse-item">
        <ng-scrollbar style="height: calc(100vh - 70px)" visibility="hover">
          @for (item of collapseItem.children; track item) {
            @if (item.type === 'item') {
              <app-nav-item [item]="item"></app-nav-item>
            } @else if (item.type === 'collapse') {
              <app-nav-collapse [item]="item"></app-nav-collapse>
            }
          }
        </ng-scrollbar>
      </div>
    </div>
  }
}
