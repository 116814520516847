// angular import
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';

// third party
import { TranslateService } from '@ngx-translate/core';
import screenfull from 'screenfull';

// icon
import { IconService } from '@ant-design/icons-angular';
import {
  WindowsOutline,
  TranslationOutline,
  BellOutline,
  MailOutline,
  FullscreenOutline,
  SettingOutline,
  FullscreenExitOutline,
  GiftOutline,
  MessageOutline,
  PhoneOutline,
  CheckCircleOutline,
  CloseOutline,
  LogoutOutline,
  EditOutline,
  UserOutline,
  ProfileOutline,
  WalletOutline,
  QuestionCircleOutline,
  LockOutline,
  CommentOutline,
  UnorderedListOutline,
  ArrowRightOutline
} from '@ant-design/icons-angular/icons';
import { MantisConfig } from 'src/app/app-config';

@Component({
  selector: 'app-nav-right',
  standalone: true,
  imports: [SharedModule, RouterModule],
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent implements OnInit {
  // public props
  windowWidth: number;
  screenFull: boolean = true;

  // constructor
  constructor(
    private translate: TranslateService,
    private iconService: IconService
  ) {
    this.windowWidth = window.innerWidth;
    this.iconService.addIcon(
      ...[
        WindowsOutline,
        TranslationOutline,
        BellOutline,
        MailOutline,
        FullscreenOutline,
        SettingOutline,
        FullscreenExitOutline,
        GiftOutline,
        MessageOutline,
        SettingOutline,
        PhoneOutline,
        CheckCircleOutline,
        CloseOutline,
        LogoutOutline,
        EditOutline,
        UserOutline,
        ProfileOutline,
        WalletOutline,
        QuestionCircleOutline,
        LockOutline,
        CommentOutline,
        UnorderedListOutline,
        ArrowRightOutline
      ]
    );
  }

  // life cycle hook
  ngOnInit() {
    setTimeout(() => {
      this.useLanguage(MantisConfig.i18n);
    });
  }

  // user according language change of sidebar menu item
  useLanguage(language: string) {
    this.translate.use(language);
  }

  component = [
    {
      title: 'Login',
      url: '/'
    },
    {
      title: 'Register',
      url: '/'
    },
    {
      title: 'Reset Password',
      url: '/'
    },
    {
      title: 'Forgot Password',
      url: '/'
    },
    {
      title: 'Verification Code',
      url: '/'
    }
  ];

  component2 = [
    {
      title: 'About Us',
      url: '/',
      target: '_self'
    },
    {
      title: 'Contact Us',
      url: '/',
      target: '_self'
    },
    {
      title: 'Pricing',
      url: '/'
    },
    {
      title: 'Payment',
      url: '/'
    },
    {
      title: 'Construction',
      url: '/',
      target: '_self'
    },
    {
      title: 'Coming Soon',
      url: '/',
      target: '_self'
    }
  ];

  component3 = [
    {
      title: '404 Error',
      url: '/'
    },
    {
      title: 'Landing',
      url: '/'
    }
  ];

  profile = [
    {
      icon: 'edit',
      title: 'Edit Profile'
    },
    {
      icon: 'user',
      title: 'View Profile'
    },
    {
      icon: 'profile',
      title: 'Social Profile'
    },
    {
      icon: 'wallet',
      title: 'Billing'
    }
  ];

  setting = [
    {
      icon: 'question-circle',
      title: 'Support'
    },
    {
      icon: 'user',
      title: 'Account Settings'
    },
    {
      icon: 'lock',
      title: 'Privacy Center'
    },
    {
      icon: 'comment',
      title: 'Feedback'
    },
    {
      icon: 'unordered-list',
      title: 'History'
    }
  ];

  // full screen toggle
  toggleFullscreen() {
    this.screenFull = screenfull.isFullscreen;
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }
}
