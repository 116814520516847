<nav>
  <div class="navbar-wrapper">
    <div class="m-header">
      <a href="index.html" class="b-brand">
        <!-- ========   Change your logo from here   ============ -->
        @if (darkMode === false) {
          <img src="assets/images/logo-dark.svg" alt="theme-logo" class="logo logo-dark logo-lg" />
          <img src="assets/images/logo-icon.svg" alt="mini menu logo" class="logo logo-sm" />
        } @else {
          <img src="assets/images/logo-white.svg" alt="dark mode logo" class="logo logo-white logo-lg" />
        }
      </a>
    </div>
    <app-nav-content
      (NavCollapsedMob)="navCollapseMob()"
      (SubmenuCollapse)="navSubmenuCollapse()"
      class="scroll-div w-100 compact"
    ></app-nav-content>
  </div>
</nav>
