// Angular import
import { Component } from '@angular/core';

// project import
import { ConfigurationComponent } from '../admin-layout/configuration/configuration.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-guest',
  standalone: true,
  imports: [ConfigurationComponent, RouterModule],
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
  // imports: [AdminBreadcrumbComponent]
})
export class GuestLayouts {}
