@if (!item.hidden) {
  <li class="nav-item coded-menu-caption" [ngClass]="item.classes!">
    <label>{{ item.title | translate }}</label>
  </li>
  @for (item of item.children; track item) {
    @if (item.type === 'collapse') {
      <app-nav-collapse [item]="item" (showCollapseItem)="subMenuCollapse($event)"></app-nav-collapse>
    } @else if (item.type === 'item') {
      <app-nav-item [item]="item"></app-nav-item>
    }
  }
}
