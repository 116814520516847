// Angular import
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule, Location, LocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';

// project import
import { NavigationItem, NavigationItems } from '../navigation';
import { MantisConfig } from 'src/app/app-config';
import { environment } from 'src/environments/environment';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NavCollapseComponent } from './nav-collapse/nav-collapse.component';
import { NavGroupComponent } from './nav-group/nav-group.component';
import { NavItemComponent } from './nav-item/nav-item.component';

// icon
import { IconService } from '@ant-design/icons-angular';
import {
  DashboardOutline,
  IdcardOutline,
  DatabaseOutline,
  LineChartOutline,
  MessageOutline,
  CalendarOutline,
  CustomerServiceOutline,
  UserOutline,
  ShoppingCartOutline,
  LayoutOutline,
  CreditCardOutline,
  GoldOutline,
  ShareAltOutline,
  CloudUploadOutline,
  FormOutline,
  EditOutline,
  FileProtectOutline,
  FileImageOutline,
  FileTextOutline,
  UploadOutline,
  InsertRowAboveOutline,
  TableOutline,
  PieChartOutline,
  LoginOutline,
  RocketOutline,
  PhoneOutline,
  DollarOutline,
  AuditOutline,
  QuestionOutline,
  ChromeOutline,
  RightOutline
} from '@ant-design/icons-angular/icons';

@Component({
  selector: 'app-nav-content',
  standalone: true,
  imports: [SharedModule, CommonModule, RouterModule, NavCollapseComponent, NavGroupComponent, NavItemComponent],
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements AfterViewInit, OnInit {
  // After Media 1025 menu Open In Use
  @Output() NavCollapsedMob: EventEmitter<string> = new EventEmitter();

  // for Compact Menu
  @Output() SubmenuCollapse: EventEmitter<string> = new EventEmitter();

  // Theme version
  title = 'Demo application for version numbering';
  currentApplicationVersion = environment.appVersion;

  mantisConfig = MantisConfig;
  layout!: string;
  navigations: NavigationItem[];
  prevDisabled: string;
  nextDisabled: string;
  contentWidth: number;
  wrapperWidth!: number;
  scrollWidth: number;
  windowWidth: number;
  collapseItem!: NavigationItem;

  @ViewChild('navbarContent', { static: false }) navbarContent!: ElementRef;
  @ViewChild('navbarWrapper', { static: false }) navbarWrapper!: ElementRef;

  // Constructor
  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private iconService: IconService
  ) {
    this.windowWidth = window.innerWidth;
    this.iconService.addIcon(
      ...[
        DashboardOutline,
        IdcardOutline,
        DatabaseOutline,
        LineChartOutline,
        MessageOutline,
        CalendarOutline,
        CustomerServiceOutline,
        UserOutline,
        ShoppingCartOutline,
        LayoutOutline,
        CreditCardOutline,
        GoldOutline,
        ShareAltOutline,
        CloudUploadOutline,
        FormOutline,
        EditOutline,
        FileProtectOutline,
        FileImageOutline,
        FileTextOutline,
        UploadOutline,
        InsertRowAboveOutline,
        TableOutline,
        PieChartOutline,
        LoginOutline,
        RocketOutline,
        PhoneOutline,
        DollarOutline,
        AuditOutline,
        QuestionOutline,
        ChromeOutline,
        RightOutline
      ]
    );
    this.navigations = NavigationItems;
    this.prevDisabled = 'disabled';
    this.nextDisabled = '';
    this.scrollWidth = 0;
    this.contentWidth = 0;
  }

  ngOnInit() {
    this.layout = MantisConfig.layout;
  }

  // public method
  ngAfterViewInit() {
    if (MantisConfig.layout === 'horizontal') {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }

  // Horizontal Menu
  scrollPlus() {
    this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 200);
    if (this.scrollWidth > this.contentWidth - this.wrapperWidth) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 200;
      this.nextDisabled = 'disabled';
    }
    this.prevDisabled = '';
    (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
  }

  scrollMinus() {
    this.scrollWidth = this.scrollWidth - this.wrapperWidth;
    if (this.scrollWidth < 0) {
      this.scrollWidth = 0;
      this.prevDisabled = 'disabled';
    }
    this.nextDisabled = '';
    (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
  }

  fireLeave() {
    const sections = document.querySelectorAll('.coded-hasmenu');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('active');
      sections[i].classList.remove('coded-trigger');
    }

    let current_url = this.location.path();
    // eslint-disable-next-line
    // @ts-ignore
    if (this.location['_baseHref']) {
      // eslint-disable-next-line
      // @ts-ignore
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent?.parentElement?.parentElement;
      const last_parent = up_parent?.parentElement;
      if (parent?.classList.contains('coded-hasmenu')) {
        parent.classList.add('active');
      } else if (up_parent?.classList.contains('coded-hasmenu')) {
        up_parent.classList.add('active');
      } else if (last_parent?.classList.contains('coded-hasmenu')) {
        last_parent.classList.add('active');
      }
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent?.parentElement?.parentElement;
      const last_parent = up_parent?.parentElement;
      if (parent?.classList.contains('coded-hasmenu')) {
        if (MantisConfig.layout === 'vertical') {
          parent.classList.add('coded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent?.classList.contains('coded-hasmenu')) {
        if (MantisConfig.layout === 'vertical') {
          up_parent.classList.add('coded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent?.classList.contains('coded-hasmenu')) {
        if (MantisConfig.layout === 'vertical') {
          last_parent.classList.add('coded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

  navMob() {
    if (this.windowWidth < 1025 && document.querySelector('app-navigation.pc-sidebar')?.classList.contains('mob-open')) {
      this.NavCollapsedMob.emit();
    }
  }

  // eslint-disable-next-line
  subMenuCollapse(item: any) {
    this.SubmenuCollapse.emit();
    this.collapseItem = item;
  }
}
