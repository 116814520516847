<header class="pc-header">
  <div class="header-wrapper">
    <div class="pc-mob-drp">
      <app-nav-left [navCollapsed]="navCollapsed" (NavCollapse)="navCollapse()" (NavCollapsedMob)="navCollapseMob()"></app-nav-left>
    </div>
    <div>
      <app-nav-right></app-nav-right>
    </div>
  </div>
</header>
