<ul class="list-unstyled">
  <li class="pc-h-item pc-mega-menu" ngbDropdown placement="auto">
    <a class="pc-head-link arrow-none me-0" ngbDropdownToggle>
      <i antIcon type="windows" theme="outline"></i>
    </a>
    <div class="pc-h-dropdown pc-mega-dropdown" ngbDropdownMenu>
      <div class="row g-0">
        <div class="col-4">
          <div class="col image-block">
            <h2 class="text-white">Explore Components</h2>
            <p class="text-white my-4">Try our pre made component pages to check how it feels and suits as per your need.</p>
            <div class="row align-items-end">
              <div class="col-auto">
                <div class="btn btn btn-light">
                  View All
                  <i class="" antIcon theme="outline" type="arrow-right"></i>
                </div>
              </div>
              <div class="col">
                <img src="assets/images/mega-menu/chart.svg" alt="image" class="img-fluid img-charts" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="row p-4">
            <div class="col">
              <h6 class="mega-title">Authentication</h6>
              <ul class="pc-mega-list">
                @for (list of component; track list) {
                  <div>
                    <li>
                      <a class="dropdown-item" [routerLink]="[list.url]" target="_blank">
                        <span class="dot-badge"></span>
                        {{ list.title }}
                      </a>
                    </li>
                  </div>
                }
              </ul>
            </div>
            <div class="col">
              <h6 class="mega-title">Other Pages</h6>
              <ul class="pc-mega-list">
                @for (list of component2; track list) {
                  <div>
                    <li>
                      <a class="dropdown-item" [routerLink]="[list.url]" [target]="list.target">
                        <span class="dot-badge ms-2"></span>
                        {{ list.title }}
                      </a>
                    </li>
                  </div>
                }
              </ul>
            </div>
            <div class="col">
              <h6 class="mega-title">SAAS Pages</h6>
              <ul class="pc-mega-list">
                @for (list of component3; track list) {
                  <div>
                    <li>
                      <a class="dropdown-item" [routerLink]="[list.url]" target="_blank">
                        <span class="dot-badge ms-2"></span>
                        {{ list.title }}
                      </a>
                    </li>
                  </div>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li class="dropdown pc-h-item" ngbDropdown>
    <a class="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" ngbDropdownToggle>
      <i antIcon type="translation" theme="outline"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
      <a (click)="useLanguage('en')" class="dropdown-item">
        <span>
          English
          <small>(UK)</small>
        </span>
      </a>
      <a (click)="useLanguage('fr')" class="dropdown-item">
        <span>
          français
          <small>(French)</small>
        </span>
      </a>
      <a (click)="useLanguage('ro')" class="dropdown-item">
        <span>
          Română
          <small>(Romanian)</small>
        </span>
      </a>
      <a (click)="useLanguage('cn')" class="dropdown-item">
        <span>
          中国人
          <small>(Chinese)</small>
        </span>
      </a>
    </div>
  </li>
  <li class="dropdown pc-h-item" ngbDropdown>
    <a class="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" ngbDropdownToggle>
      <i antIcon type="bell" theme="outline"></i>
      <span class="badge bg-primary pc-h-badge">3</span>
    </a>
    <div class="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
      <div class="dropdown-header d-flex align-items-center justify-content-between">
        <h5 class="m-0">Notification</h5>
        <a class="bg-transparent"><i class="text-success d-flex f-20" antIcon theme="outline" type="check-circle"></i></a>
      </div>
      <div class="dropdown-divider"></div>
      <ng-scrollbar style="min-height: 300px" visibility="hover">
        <div class="dropdown-header px-0 text-wrap header-notification-scroll">
          <div class="list-group list-group-flush w-100">
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <div class="user-avatar bg-light-success"><i class="d-flex" antIcon theme="outline" type="gift"></i></div>
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">3:00 AM</span>
                  <p class="text-body mb-0">
                    It's
                    <b>Cristina danny's</b>
                    birthday today.
                  </p>
                  <span class="text-muted">2 min ago</span>
                </div>
              </div>
            </a>
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <div class="user-avatar bg-light-primary"><i class="d-flex" antIcon theme="outline" type="message"></i></div>
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">6:00 PM</span>
                  <p class="text-body mb-1">
                    <b>Aida Burg</b>
                    commented your post.
                  </p>
                  <span class="text-muted">5 August</span>
                </div>
              </div>
            </a>
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <div class="user-avatar bg-light-danger"><i class="d-flex" antIcon theme="outline" type="setting"></i></div>
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">2:45 PM</span>
                  <p class="text-body mb-1">
                    Your Profile is Complete &nbsp;
                    <b>60%</b>
                  </p>
                  <span class="text-muted">7 hours ago</span>
                </div>
              </div>
            </a>
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <div class="user-avatar bg-light-primary"><i class="d-flex" antIcon theme="outline" type="phone"></i></div>
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">9:10 PM</span>
                  <p class="text-body mb-1">
                    <b>Cristina Danny</b>
                    invited to join
                    <b>Meeting.</b>
                  </p>
                  <span class="text-muted">Daily scrum meeting time</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </ng-scrollbar>
      <div class="dropdown-divider"></div>
      <div class="text-center py-2">
        <a class="link-primary">View all</a>
      </div>
    </div>
  </li>
  <li class="dropdown pc-h-item" ngbDropdown>
    <a class="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" ngbDropdownToggle>
      <i antIcon type="mail" theme="outline"></i>
    </a>
    <div class="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
      <div class="dropdown-header d-flex align-items-center justify-content-between">
        <h5 class="m-0">Message</h5>
        <a class="bg-transparent"><i class="d-felx f-18 text-danger" antIcon theme="outline" type="close"></i></a>
      </div>
      <div class="dropdown-divider"></div>
      <ng-scrollbar style="min-height: 300px" visibility="hover">
        <div class="dropdown-header px-0 text-wrap header-notification-scroll">
          <div class="list-group list-group-flush w-100">
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avatar" />
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">3:00 AM</span>
                  <p class="text-body mb-0">
                    It's
                    <b>Cristina danny's</b>
                    birthday today.
                  </p>
                  <span class="text-muted">2 min ago</span>
                </div>
              </div>
            </a>
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <img src="assets/images/user/avatar-1.jpg" alt="user-image" class="user-avatar" />
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">6:00 PM</span>
                  <p class="text-body mb-0">
                    <b>Aida Burg</b>
                    commented your post.
                  </p>
                  <span class="text-muted">5 August</span>
                </div>
              </div>
            </a>
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <img src="assets/images/user/avatar-3.jpg" alt="user-image" class="user-avatar" />
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">2:45 PM</span>
                  <p class="text-body mb-0"><b>There was a failure to your setup.</b></p>
                  <span class="text-muted">7 hours ago</span>
                </div>
              </div>
            </a>
            <a class="list-group-item list-group-item-action">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <img src="assets/images/user/avatar-4.jpg" alt="user-image" class="user-avatar" />
                </div>
                <div class="flex-grow-1 ms-3">
                  <span class="float-end text-muted">9:10 PM</span>
                  <p class="text-body mb-0">
                    <b>Cristina Danny</b>
                    invited to join
                    <b>Meeting.</b>
                  </p>
                  <span class="text-muted">Daily scrum meeting time</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </ng-scrollbar>
      <div class="dropdown-divider"></div>
      <div class="text-center py-2">
        <a class="link-primary">View all</a>
      </div>
    </div>
  </li>
  <li class="dropdown pc-h-item" (click)="toggleFullscreen()">
    <a class="pc-head-link me-0">
      @if (screenFull === true) {
        <i antIcon type="fullscreen" theme="outline"></i>
      } @else {
        <i antIcon type="fullscreen-exit" theme="outline"></i>
      }
    </a>
  </li>
  <li class="dropdown pc-h-item">
    <a class="pc-head-link me-0">
      <i class="settings" antIcon type="setting" theme="outline"></i>
    </a>
  </li>
  <li class="dropdown pc-h-item header-user-profile" ngbDropdown>
    <a class="pc-head-link dropdown-toggle arrow-none me-0" ngbDropdownToggle>
      <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avatar me-2" />
      <span class="f-w-600">JWT User</span>
    </a>
    <div class="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
      <div class="dropdown-header">
        <div class="d-flex mb-1">
          <div class="flex-shrink-0">
            <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avatar wid-35" />
          </div>
          <div class="flex-grow-1 ms-3 text-start">
            <h6 class="mb-0">JWT User</h6>
            <span>UI/UX Designer</span>
          </div>
          <a href="javascript:" class="bg-transparent"><i class="d-flex f-20" antIcon theme="outline" type="logout"></i></a>
        </div>
      </div>
      <ul ngbNav #nav="ngbNav" class="nav drp-tabs nav-fill nav-tabs">
        <li ngbNavItem="1">
          <a ngbNavLink>
            <i antIcon theme="outline" type="user" class="me-2"></i>
            Profile
          </a>
          <ng-template ngbNavContent>
            @for (task of profile; track task) {
              <div>
                <a class="dropdown-item">
                  <i class="text-muted" antIcon theme="outline" type="{{ task.icon }}"></i>
                  <span>{{ task.title }}</span>
                </a>
              </div>
            }
            <div>
              <a class="dropdown-item">
                <i class="text-muted" antIcon theme="outline" type="logout"></i>
                <span>Logout</span>
              </a>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem="2">
          <a ngbNavLink>
            <i class="me-2" antIcon theme="outline" type="setting"></i>
            Setting
          </a>
          <ng-template ngbNavContent>
            @for (task of setting; track task) {
              <div>
                <a class="dropdown-item">
                  <i class="text-muted" antIcon theme="outline" type="{{ task.icon }}"></i>
                  <span>{{ task.title }}</span>
                </a>
              </div>
            }
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </li>
</ul>
<!-- [Mobile Media Block end] -->
