@if (!item.hidden) {
  <!-- Horizontal Layout -->
  @if (themeLayout === 'horizontal') {
    <li
      (mouseenter)="navCollapse($event)"
      data-username="mantis dashboard"
      class="nav-item coded-hasmenu"
      [routerLinkActive]="['active']"
      [ngClass]="item.groupClasses"
    >
      <a [routerLinkActive]="['active']" class="arrow-edge">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </a>
      <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
  }

  <!-- Vertical Layout -->
  @if (themeLayout === 'vertical') {
    <li data-username="mantis dashboard" class="nav-item coded-hasmenu" [routerLinkActive]="['active']">
      <a [routerLinkActive]="['active']" (click)="navCollapse($event)">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </a>
      <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
  }

  <!-- Compact Layout -->
  @if (themeLayout === 'compact') {
    <li
      data-username="mantis dashboard"
      class="nav-item coded-hasmenu"
      [routerLinkActive]="['active']"
      (click)="subMenuCollapse(item)"
      ngbTooltip="{{ item.title }}"
    >
      <a>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </a>
      <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
  }

  <!-- all layout common -->
  <ng-template #itemContent>
    @if (item.icon) {
      <span class="coded-micon">
        <i antIcon type="{{ item.icon }}" theme="outline"></i>
      </span>
    }
    <span class="coded-mtext">
      {{ item.title | translate }}
    </span>
  </ng-template>
  <ng-template #subMenuContent>
    <ul class="coded-submenu" [routerLinkActive]="['active']" [ngClass]="item.classes!">
      @for (item of item.children; track item) {
        @if (item.type === 'item') {
          <app-nav-item [item]="item"></app-nav-item>
        } @else if (item.type === 'collapse') {
          <app-nav-collapse [item]="item"></app-nav-collapse>
        }
      }
    </ul>
  </ng-template>
}
